import './App.css';
import './premium.css';
import {Routes, Route} from 'react-router-dom';
import premium from '@formio/premium';
//import { Templates } from '@formio/react';
// import semantic from '@formio/semantic';
import { Formio } from '@formio/react';



// components
import { Header } from './components/Header';
import { Home } from './components/Home';
import { EtatDesLieux } from './components/EtatDesLieux';
import { EtatVehicule } from './components/EtatVehicule';

//templates
import componentModal from './templates/componentModal';
import file from './templates/file';
import sketchpad from './templates/sketchpad';
import { SuiviHeures } from './components/SuiviHeures';
import { ReaderQr } from './components/ReaderQr';

// formio implementation
Formio.use(premium);
Formio.Templates.current = { file: { form: file } };
Formio.Templates.current = { componentModal: { form: componentModal}};
Formio.Templates.current = { sketchpad: { form: sketchpad}};

function App() {
  
  return (
    <>
    <Header />
    <Routes>
      <Route path='/' element={<Home />}/>
      
      <Route path='/etatdeslieux' element={<EtatDesLieux />} />
      <Route path='/etatvehicule' element={<EtatVehicule />} />
      <Route path='/suiviheures' element={<SuiviHeures />} />
      <Route path='/lecteurqr' element={<ReaderQr />} />
    </Routes>
      
    </>
  );
}

export default App;
