import React from 'react';
import { Link } from 'react-router-dom';

export const Home = () => {
  return (
    <main>
        <Link to='/etatdeslieux'>Etat des lieux immobilier</Link>
        <Link to='/etatvehicule'>Etat des lieux véhicule</Link>
        <Link to='/suiviheures'>Suivi heures</Link>
        <Link to='/lecteurqr'>Lecteur QR code</Link>

  </main>
  )
}
