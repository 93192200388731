import React, { useState } from "react";
import { QrReader } from "react-qr-reader";

export const ReaderQr = () => {
    const [data, setData] = useState("pas de résultat");

  return (
    <div>
              
      <p className="result">Données liées au QR code : {data}</p>
      <QrReader
        constraints={{ facingMode: "environment" }}
        onResult={(result, error) => {
          if (!!result) {
            setData(result?.text);
          }

          if (!!error) {
            console.info(error);
          }
        }}
        className='reader'
      />
      
    </div>
  )
}
