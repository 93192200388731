import React from 'react';
import { Form } from '@formio/react';
import { Link } from 'react-router-dom';



export const SuiviHeures = () => {
  return (
    <>
        <Link to='/' className='return'>Retour à l'accueil</Link>
        <Form src="https://form.ido-data.fr/tests/suiviheures" 
                                                    options={{ 
                                                        language: 'fr', 
                                                        i18n: {
                                                        fr: {
                                                            cancel: 'Annuler',
                                                            previous: 'Précédent',
                                                            next: 'Suivant',
                                                            submit: 'Envoyer',
                                                            complete: 'Envoi réussi'
                                                        }
                                                        }
                                                    }}/>
    </>
  )
}
