import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@formio/react';

export const EtatDesLieux = () => {
  return (
    <>
    <Link to='/' className='return'>Retour à l'accueil</Link>
    <Form src="https://form.ido-data.fr/tests/etatdeslieux" 
                                                  options={{ 
                                                    language: 'fr', 
                                                    i18n: {
                                                      fr: {
                                                        cancel: 'Annuler',
                                                        previous: 'Précédent',
                                                        next: 'Suivant',
                                                        submit: 'Envoyer',
                                                        complete: 'Envoi réussi'
                                                      }
                                                    }
                                                  }}/>
    </>
  )
}
