Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default=function(ctx) {
  var __t, __p = '', __j = Array.prototype.join;
  function print() { __p += __j.call(arguments, '') }

  __p += '<div class="formio-sketchpad-body">\n  <div class="formio-sketchpad-header" ref="header">\n    <div class="btn-toolbar formio-sketchpad-toolbar" role="toolbar">\n      ';
   
  __p += '\n        <div class="btn-group formio-sketchpad-toolbar-group" role="group">\n          ';

  __p += '\n            <div class="btn active formio-sketchpad-toolbar-button formio-sketchpad-toolbar-button-' +
  ((__t = (ctx.buttonGroups[0][2].key)) == null ? '' : __t) +
  ' " ref="' +
  ((__t = (ctx.buttonGroups[0][2].key)) == null ? '' : __t) +
  '" title="' +
  ((__t = (ctx.buttonGroups[0][2].title)) == null ? '' : __t) +
  '">\n              <i class="' +
  ((__t = (ctx.buttonGroups[0][2].icon)) == null ? '' : __t) +
  '"></i>\n              ';
   if (ctx.buttonGroups[0][2].input) { ;
  __p += '\n                <input type="' +
  ((__t = (ctx.buttonGroups[0][2].input.type)) == null ? '' : __t) +
  '" class="formio-sketchpad-toolbar-input ' +
  ((__t = (ctx.buttonGroups[0][2].input.class)) == null ? '' : __t) +
  '" ref="' +
  ((__t = (ctx.buttonGroups[0][2].key)) == null ? '' : __t) +
  '-input"\n                ' +
  ((__t = ( ctx.self.buttonAttributes(ctx.buttonGroups[0][2]) )) == null ? '' : __t) +
  '/>\n              ';
   } ;

  __p += '\n            </div>\n          ';/*close toolbar-button*/
 ;
 __p += '\n            <div class="btn formio-sketchpad-toolbar-button formio-sketchpad-toolbar-button-' +
 ((__t = (ctx.buttonGroups[2][3].key)) == null ? '' : __t) +
 '" ref="' +
 ((__t = (ctx.buttonGroups[2][3].key)) == null ? '' : __t) +
 '" title="' +
 ((__t = (ctx.buttonGroups[2][3].title)) == null ? '' : __t) +
 '">\n              <i class="' +
 ((__t = (ctx.buttonGroups[2][3].icon)) == null ? '' : __t) +
 '"></i>\n            </div>\n  ';/*close toolbar-button*/
  __p += '\n        </div>\n      '; /*close toolbar-group*/
   ;

 /*close toolbar + close header*/ __p += '\n    </div>\n   </div>\n  <div class="formio-sketchpad-container" ref="drawingContainer">\n    <div class="formio-sketchpad-background" ref="background">\n      ';
   if (!ctx.component.image && !ctx.component.imageUrl) { ;
  __p += '\n          Background image is not specified. Sketchpad doesn\\\'t work without background image\n      ';
   } ;
  __p += '\n      ';
   if (ctx.component.imageUrl && !(ctx.component.imageType === 'svg')) { ;
  __p += '\n        <img ref="backgroundImage" alt=""/>\n      ';
   } ;
  __p += '\n    </div>\n    <div class="formio-sketchpad-canvas" ref="canvas"></div>\n  </div>\n  <div class="formio-sketchpad-footer" ref="footer">\n    <div class="formio-sketchpad-meta-info"><div><span ref="totalMultiplier">' +
  ((__t = (ctx.zoomInfo.totalMultiplier)) == null ? '' : __t) +
  '</span>x</div>\n  </div>\n</div></div>\n';
  return __p
}
